import React from "react";
import styled from "styled-components";
import { AiOutlineEye } from "react-icons/ai";
import { Helmet } from "react-helmet";

import { Tags, TableOfContents } from "./metadata/index";

import {
  PostViewCounter,
  CounterDisplay
} from "./counter/index";

import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";


const PostSubtitle = ({ children }) => {
  return (
    <div className="flex items-center">
      {children}
    </div>
  );
}

const PostSubtitleItem = ({ children }) => {
  return (
    <div className="block text-xs mr-2">
      {children}
    </div>
  );
}

const PostContent = styled.div`
  text-align: justify;
  p {
    // top right bottom left
    margin: 0 0 0.25rem 0;
  }

  // level 1-3 unordered list
  ul {
    list-style-type: disc;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square;
      }
    }
  }

  // ordered list
  ol {
    list-style-type: decimal;
  }

  ul, ol, li {
    margin: 0 0 0rem 1rem;
  }

  // for the bookmark link
  a.anchor {
    fill: #4C566A; // black-3
  }

  svg {
    display: inline;
    margin-left: 0.25rem;
  }

  h1 {
    font-size: 2.25em;
  }

  h2 {
    font-size: 1.75em;
  }

  h1, h2 {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
  }

  h3 {
    font-size: 1.5em;
  }

  h3, h4 {
    margin-top: 0.5rem;
    margin-bottom: 0.1rem;
  }
`;

const SideColumnWrapper = styled.div`
  flex: 1;
  padding-left: 1rem;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }
`;

const MetadataWrapper = styled.div`
  margin-bottom: 1rem;
  width: 17rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

// Placeholder: used to replace rendering elements
const components = {
}

class TwoColumnsPost extends React.Component {

  render() {
    let postTitle = this.props.postTitle;
    let post = this.props.post;
    let tags = this.props.tags;
    // Busuanzi counter
    let number = <span id="busuanzi_value_page_pv"></span>;
    var helmet = <></>;
    if (typeof window !== `undefined`) {
      helmet =
        <Helmet>
          <script async src="//busuanzi.ibruce.info/busuanzi/2.3/busuanzi.pure.mini.js"></script>
        </Helmet>;
    }

    return (
      <div>
        <div className="mb-4">
          {helmet}
          <h1 className="mb-2 text-3xl">{postTitle}</h1>
          <PostSubtitle>
            <PostSubtitleItem>
              {post.frontmatter.date}
            </PostSubtitleItem>
            <PostSubtitleItem>
              <CounterDisplay
                icon={<AiOutlineEye size={"2em"} />}
                number={number} />
            </PostSubtitleItem>
          </PostSubtitle>
        </div>

        <div className="post-container lg:post-container">
          <div className="post-content lg:post-content">
            <PostContent id="postContent">
              <MDXProvider components={components}>
                <MDXRenderer>
                  {post.body}
                </MDXRenderer>
              </MDXProvider>
            </PostContent>
          </div>

          <SideColumnWrapper>
            <MetadataWrapper>
              <Tags tags={tags} />
            </MetadataWrapper>
            <TableOfContents
              post={post}
            />

          </SideColumnWrapper>
        </div>
      </div>
    )
  }
}

export default TwoColumnsPost
