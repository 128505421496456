import React from "react";
import styled from "styled-components";
import { AiFillHeart } from "react-icons/ai";
import { AbstractCounter, CounterDisplay, NumberSkeleton } from "./index";
import { GetDataFromEndpoint } from "../service/Heroku";

const LikeIcon = styled(AiFillHeart)`
  cursor: pointer;
  animation-name: lightup;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  :hover {
    animation-play-state: paused;
  }
`

class PostLikeCounter extends AbstractCounter {

  constructor(props) {
    super(props);
    this.HandleLike = this.HandleLike.bind(this);
    this.state = {
      display: false,
      liked: false,
      result: {},
    }
  }

  GetAPIPath() {
    return "api/pl_count";
  }

  componentDidMount() {
    this.GetResult();
  }

  // for update the like count only
  HandleLike() {
    let service_host = this.GetServiceHost();
    let api_path = "api/like_post";
    let page_id = this.GetPageId();
    let like_url = `${service_host}/${api_path}/${page_id}`;

    GetDataFromEndpoint(like_url).then(
      (response) => {
        this.setState({
          display: true,
          result: response,
        });
      });
    this.setState({ liked: true });
  }

  render() {
    const { display, result } = this.state;
    var number = <NumberSkeleton />;
    var icon = <LikeIcon onClick={this.HandleLike} size={"2em"} />;

    if (display) {
      number = result.like_count;
    }
    if (this.state.liked) {
      icon = <AiFillHeart size={"2em"} fill={"#BF616A"} />;
    }

    return (
      <CounterDisplay
        icon={icon}
        number={number} />
    );
  }
}

export {
  PostLikeCounter,
}
