import React from "react";

import {
  ProcessBarLink,
  ProcessBarReversedLink,
} from "./animated/index";

function formatTitle(title) {
  const chunk = 20
  if (title.length > chunk) {
    title = title.substr(0, chunk) + '...'
  }
  return title;
}

const NewerPost = ({ post }) => {
  if (!post) {
    return <div></div>;
  }

  return (
    <div>
      <span><b>{"Newer Post"}</b></span>
      <h4>
        <ProcessBarReversedLink to={`/${post.slug}`} >
          {formatTitle(post.frontmatter.title)}
        </ProcessBarReversedLink>
      </h4>
    </div>
  );
}

const OlderPost = ({ post }) => {
  if (!post) {
    return <div></div>;
  }

  return (
    <div>
      <span><b>{"Older Post"}</b></span>
      <h4>
        <ProcessBarLink to={`/${post.slug}`} >
          {formatTitle(post.frontmatter.title)}
        </ProcessBarLink>
      </h4>
    </div>
  );
}

export default function PostNav({ next, previous }) {
  return (
    <div>
      <div className="flex flex-wrap justify-between">
        <NewerPost post={next} />
        <OlderPost post={previous} />
      </div>
    </div>
  );
}
