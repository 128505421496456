import React from "react";
import { DiscussionEmbed } from "disqus-react";
import { graphql } from "gatsby";

import { Bio } from "../components/metadata/index";
import {
  Container,
  Layout,
} from "../components/page";
import { SEO } from "../components/metadata/index";
import PostNav from "../components/PostNav";
import TwoColumnsPost from "../components/TwoColumnsPost";

const Post = ({ data, pageContext, location }) => {
  const post = data.mdx
  const disqusConfig = {
    shortname: 'junhan-xyz',
    config: { identifier: post.slug },
  }

  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const postTitle = post.frontmatter.title

  var tags = post.frontmatter.tags
  if (tags) {
    tags = tags.map(tag => {
      return { 'fieldValue': tag }
    })
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={postTitle}
      />

      <Container>
        <article>
          <TwoColumnsPost
            postTitle={postTitle}
            post={post}
            tags={tags}
          />
        </article>

        <hr style={{ margin: `3rem 0 1.5rem 0`, }} />
        <Bio />
        <PostNav previous={previous} next={next} />
        <div style={{ marginTop: `4rem` }}>
          <DiscussionEmbed {...disqusConfig} />
        </div>
      </Container>
    </Layout>
  )

}

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: {eq: $slug}) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        tags
      }
      body
      headings {
        depth
        value
      }
    }
  }
`

export default Post;
