import React from "react";
import { GetDataFromEndpoint } from "../service/Heroku";
var Url = require("url-parse");

const CounterDisplay = ({ icon, number }) => {
  return (
    <div className="flex items-center">
      <div className="mr-1 counter">{icon}</div>
      <div className="mr-1">{number}</div>
    </div>
  );
}

class AbstractCounter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      display: false,
      result: {},
    }
  }

  GetAPIPath() {
    // override it 
  }

  GetServiceHost() {
    return "https://junhan-xyz-service.herokuapp.com";
  }

  GetPageId() {
    var url = new Url(window.location.href);
    let hostname = url.hostname.replaceAll('.', '-');
    let pathname = url.pathname;
    let normalized_path = pathname.substring(0, pathname.length).replaceAll('/', '-');

    return `${hostname}${normalized_path}`;
  }

  GetEndpoint() {
    let service_host = this.GetServiceHost();
    let api_path = this.GetAPIPath();
    let page_id = this.GetPageId();
    return `${service_host}/${api_path}/${page_id}`;
  }

  GetResult() {
    let endpoint = this.GetEndpoint();
    GetDataFromEndpoint(endpoint).then(
      (result) => {
        this.setState({
          display: true,
          result: result,
        });
      }
    );
  }
}

export {
  AbstractCounter,
  CounterDisplay,
}
